/*
|--------------------------------------------------------------------------
| Store > Auth > Mutations
|--------------------------------------------------------------------------
|
| This file is where the Module's mutation methods are registered.
|
| Feel free to edit this file if you have mutation methods you want
| to include.
|
*/

import LS, { USER_BOOKING_TYPE } from '@/modules/constants/localStorage'
import axios from 'axios'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import unset from 'lodash/unset'
import forEach from 'lodash/forEach'
import { listAsOptionsSetter, listSetter } from '@/modules/helpers/vuexMutations'
import appStorage from '@/modules/helpers/appStorage'
// import i18n from '../../../locales/i18n'
import i18n from '@/locales/i18nClient.js'
import uniq from 'lodash/uniq'
import EventBus from '@/modules/helpers/eventBus'
import isNull from 'lodash/isNull'
import { getAppTenantConfig } from '@/modules/helpers/multiTenancy'
import { levelsOrder } from '@/modules/constants/translatorLevel'
import * as Sentry from '@sentry/vue'
import moment from 'moment-timezone'

export default {
  /**
   * Set user sessions log list.
   *
   * @param state
   * @param payload
   */
  setUserSessionLogsData: function (state, payload) {
    state.userSessionLogs.data = payload
  },

  /**
   * Set value for state.userSessionLogs.loading.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Boolean of the loading state of the action.
   */
  setUserSessionLogsLoading(state, payload) {
    state.userSessionLogs.loading = payload
  },

  /**
   * Set the token.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {string} token - Access Token Value.
   * @returns {void}
   */
  setToken: function (state, token) {
    appStorage.set(LS.TOKEN, token)
  },
  setRefreshToken: function (state, token) {
    appStorage.set(LS.REFRESH_TOKEN, token)
  },

  /**
   * Destory the token.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @returns {void}
   */
  destroyToken(state) {
    appStorage.remove(LS.TOKEN)
    appStorage.remove(LS.REFRESH_TOKEN)
    state.isUserAuthenticated = false
    unset(axios.defaults.headers.common, 'Authorization')
  },

  /**
   * Set the User Data.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} user - User Data that was fetched from the API.
   * @returns {void}
   */
  setUser(state, user) {
    if (!isNil(user) && !isEmpty(user)) {
      // NOTE: Store the essential data ONLY! Remove the unnecessary keys.
      state.user = {
        id: user.id,
        type: user.type,
        name: user.name,
        email: user.email,
        username: user.username,
        enabled: user.enabled,
        show_2fa_qr: user.show_2fa_qr,
        tfa_enabled: user.tfa_enabled,
        is_test: user.is_test,
        is_employed_translator: user.is_employed_translator || false,
        timezone: user.timezone
      }

      appStorage.set(LS.USER, state.user)

      Sentry.setUser({
        id: user.id,
        email: user.email,
        name: user.name,
        type: user.type
      })
    }
  },

  /**
   * Destroy the User Data.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @returns {void}
   */
  destroyUser(state) {
    state.user = {}
    appStorage.remove(LS.USER)
    Sentry.setUser(null)
  },

  /**
   * @param {object} state
   * @param {int[]} authCollection
   */
  setAuthenticatedUsers(state, authCollection) {
    state.authenticatedUsers = []

    if (!(!isNil(authCollection) && !isEmpty(authCollection))) return

    forEach(authCollection, (userId) => {
      const authenticated = appStorage.get(LS.AUTH_COLLECTION_USER + userId)

      if (isNil(authenticated)) throw new Error('Stored user data was not found.')

      state.authenticatedUsers.push({
        id: parseInt(userId),
        name: authenticated.user.name,
        type: parseInt(authenticated.user.type),
        email: authenticated.user.email
      })

      EventBus.emit('user.authenticated')
    })
  },

  resetAuthenticatedUsers(state) {
    state.authenticatedUsers = []
  },

  /**
   * Set boolean value wether if the user is authenticated.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {boolean} payload - Value to be assigned.
   * @returns {void}
   */
  setIsUserAuthenticated(state, payload) {
    state.isUserAuthenticated = payload
  },

  setGuestMode(state, payload) {
    state.guestMode = payload
  },

  /**
   * Set boolean value wether if the user is employed translator.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {boolean} value - Value to be assigned.
   * @returns {void}
   */
  setIsEmployedTranslator(state, value) {
    state.isEmployedTranslator = value
  },

  /**
   * Set the isShowLoginModal.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {boolean} visible - Boolean of the visiblity state of the modal.
   * @returns {void}
   */
  setIsShowLoginModal(state, visible) {
    state.isShowLoginModal = visible
  },
  /**
   * Set the isShowTextTranslationLoginModal.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {boolean} visible - Boolean of the visiblity state of the modal.
   * @returns {void}
   */
  setIsShowTextTranslationLoginModal(state, visible) {
    state.isShowTextTranslationLoginModal = visible
  },

  /**
   * Set value for state.userMeta.data.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Value to be assigned.
   * @returns {void}
   */
  setUserMetaData(state, payload) {
    if (!payload) return

    const data = payload[Object.keys(payload)[0]] // Get the first member of the object.
    if (!isNil(data) && !isEmpty(data)) {
      state.userMeta.data = data
      appStorage.set(USER_BOOKING_TYPE, data.booking_types)
      if (!isNil(data.translator_customer_types)) {
        state.disallowedTranslatorCustomerTypes = data.translator_customer_types
          .filter((i) => i.allowed === 0 && (isNull(i.language_id) || i.language_id === 0))
          .map((o) => o.customer_type_id)
      }
    }
  },

  /**
   * Set value for state.userMeta.loading.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Boolean of the loading state of the action.
   */
  setUserMetaLoading(state, payload) {
    state.userMeta.loading = payload
  },

  /**
   * Destroy the User's Meta Data.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @returns {void}
   */
  destroyUserMeta(state) {
    state.userMeta.data = {}
  },

  /**
   * Set value for state.userFeedback.data.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Value to be assigned.
   * @returns {void}
   */
  setUserFeedbacksData(state, payload) {
    const feedbacks = payload[Object.keys(payload)[0]] // Get the first member of the object.
    if (!isNil(feedbacks) && !isEmpty(feedbacks)) {
      state.userFeedbacks.data = feedbacks
    }
  },

  /**
   * Set value for state.userMeta.loading.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Boolean of the loading state of the action.
   */
  setUserTextRecruitmentId(state, payload) {
    state.userMeta.data.recruitment_candidate_id = payload
  },

  /**
   * Set value for state.userFeedback.loading.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {boolean} payload - Boolean of the loading state of the action.
   * @returns {void}
   */
  setUserFeedbacksLoading(state, payload) {
    state.userFeedbacks.loading = payload
  },

  /**
   * Method to set the state.translatorOpts value.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setTranslatorOpts(state, payload) {
    listAsOptionsSetter(state, payload, 'translatorOpts.data')
  },

  /**
   * Method to set the state.translatorOpts.loading value.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setTranslatorOptsLoading(state, payload) {
    state.translatorOpts.loading = payload
  },

  /**
   * Method to set the state.translatorLevelOpts value.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setTranslatorLevelOpts(state, payload) {
    payload.data.data.translator_levels = payload.data.data.translator_levels
      .filter((tl) => tl.code !== 'deluxe')
      .map((level) => {
        const order = levelsOrder.find((order) => order.id === level.id)
        if (order) {
          level.order = order.order
        }
        return level
      })
      .sort((a, b) => a.order - b.order)

    listAsOptionsSetter(state, payload, 'translatorLevelOpts.data', {
      key: 'id',
      value: 'code'
    })
  },

  /**
   * Method to set the state.translatorLevelOpts.loading value.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setTranslatorLevelOptsLoading(state, payload) {
    state.translatorLevelOpts.loading = payload
  },

  /**
   * Method to set the state.municipalityUserOpts value.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setMunicipalityUserOpts(state, payload) {
    listAsOptionsSetter(
      state,
      payload,
      'municipalityUserOpts.data',
      {
        key: 'id',
        value: 'name'
      },
      {
        collection_ref: ''
      }
    )
  },

  /**
   * Method to set the state.municipalityUserOpts.loading value.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setMunicipalityUserOptsLoading(state, payload) {
    state.municipalityUserOpts.loading = payload
  },

  /**
   * Method to set the state.companyUserOpts value.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setCompanyUserOpts(state, payload) {
    listAsOptionsSetter(
      state,
      payload,
      'companyUserOpts.data',
      {
        key: 'id',
        value: 'name'
      },
      {
        collection_ref: ''
      }
    )
  },

  /**
   * Method to set the state.companyUserOpts.loading value.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setCompanyUserOptsLoading(state, payload) {
    state.companyUserOpts.loading = payload
  },

  /**
   * Method to set the state.departmentUserOpts value.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setDepartmentUserOpts(state, payload) {
    listAsOptionsSetter(
      state,
      payload,
      'departmentUserOpts.data',
      {
        key: 'id',
        value: 'name'
      },
      {
        collection_ref: ''
      }
    )
  },

  /**
   * Method to set the state.departmentUserOpts.loading value.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setDepartmentUserOptsLoading(state, payload) {
    state.departmentUserOpts.loading = payload
  },

  /**
   * Method to set the state.departmentOpts value.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setDepartmentOpts(state, payload) {
    listAsOptionsSetter(state, payload, 'departmentOpts.data', {
      key: 'id',
      value: 'name'
    })
  },

  setMunicipalityDepartmentOpts(state, payload) {
    state.municipalityDepartments = payload.data.data.departments
    listAsOptionsSetter(state, payload, 'municipalityDepartmentOpts.data', {
      key: 'id',
      value: 'name'
    })
  },

  setMunicipalityUsers(state, payload) {
    console.log('setMunicipalityUsers', payload)
    state.municipalityUsers = payload
  },

  /**
   * Method to set the state.departmentOpts.loading value.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setDepartmentOptsLoading(state, payload) {
    state.departmentOpts.loading = payload
  },

  setMunicipalityDepartmentsOptsLoading(state, payload) {
    state.municipalityDepartmentOpts.loading = payload
  },

  /**
   * Method to set the state.townOpts value.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setTownOpts(state, payload) {
    listAsOptionsSetter(state, payload, 'townOpts.data', {
      key: 'id',
      value: 'name'
    })
  },

  /**
   * Method to set the state.townOpts.loading value.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setTownOptsLoading(state, payload) {
    state.townOpts.loading = payload
  },

  /**
   * Method to set the state.customerType value.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setCustomerTypeOpts(state, payload) {
    listSetter(state, payload, { data: 'customerTypes' })
    listAsOptionsSetter(state, payload, 'customerTypeOpts.data', {
      key: 'id',
      value: 'name'
    })
  },

  /**
   * Method to set the state.customerType.loading value.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setCustomerTypeOptsLoading(state, payload) {
    state.customerTypeOpts.loading = payload
  },

  /**
   * Method to set the state.companyOpts value.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setCompanyOpts(state, payload) {
    listAsOptionsSetter(state, payload, 'companyOpts.data', {
      key: 'id',
      value: 'name'
    })
  },

  /**
   * Method to set the state.companyOpts.loading value.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setCompanyOptsLoading(state, payload) {
    state.companyOpts.loading = payload
  },

  /**
   * @param {object} state.
   * @param {boolean} value.
   */
  setIsOnMobileViewport(state, value) {
    state.isOnMobileViewport = value
  },

  /**
   * @param {object} state.
   * @param {boolean} value.
   */
  setIsShowMobilenav(state, value) {
    state.isShowMobileNav = value
  },

  /**
   * Method to set the state.userFeedback.pagination value.
   * @param {object} state.
   * @param {boolean} value.
   */
  setUserFeedbacksPagination(state, value) {
    state.userFeedbacks.pagination = value
  },

  setUserNotificationPreference(state, payload) {
    forEach(payload, (item) => {
      // console.log('notification-references',item)
      if (item.template) {
        state.userNotificationPreferences.specific_templates[item.template.template_name] = item
      }
    })
  },

  setBlackListedMunicipalities(state, payload) {
    forEach(payload, (item) => {
      state.black_listed_municipalities.push(item.municipality_id)
    })
    state.black_listed_municipalities = uniq(state.black_listed_municipalities)
  },

  setUserNotificationLoading(state, payload) {
    state.userNotificationPreferences.loading = payload
  },

  saveLanguage(state, payload) {
    state.language = payload
    i18n.locale = payload
    appStorage.set('app_language', payload)
  },

  loadLanguage(state) {
    const lang = appStorage.get('app_language')
    if (!isNil(lang)) {
      i18n.locale = lang
      state.language = lang
    } else {
      let defaultLanguage = import.meta.env.VITE_I18N_LOCALE != null ? import.meta.env.VITE_I18N_LOCALE : 'se'
      const appTenantConfig = getAppTenantConfig()
      if (appTenantConfig) {
        defaultLanguage = appTenantConfig.default_locale
      }
      i18n.locale = defaultLanguage
      state.language = defaultLanguage
    }
  },

  setUserFiles(state, payload) {
    state.userFiles = payload
  },

  setUserFilesLoading(state, payload) {
    state.userFilesLoading = payload
  },

  /**
   *
   * @param state
   * @param userId
   */
  clearPusher(state, userId = null) {
    userId = userId === null ? LS.USER.id : userId

    if (window.Echo) {
      window.Echo.leave(`ticketCommented.${userId}`)
    }
  },

  setMunicipalities(state, payload) {
    state.municipalityList = payload.municipalities
  },

  setTrainingOptsLoading(state, payload) {
    state.trainingOpts.loading = payload
  },

  setTrainingOpts(state, payload) {
    state.trainingOpts.data = payload
  },

  setIsLoadingRegister(state, payload) {
    state.isLoadingRegister = payload
  },
  setVideoSettings(state, payload) {
    state.videoSettings = payload
  },
  showRegisterFeatureImage(state) {
    state.registerPage.showFeatureImage = true
  },
  hideRegisterFeatureImage(state) {
    state.registerPage.showFeatureImage = false
  },
  setRegisterFeatureType(state, payload) {
    state.registerPage.featureImageType = payload
  },
  setLoginCreds(state, payload) {
    state.loginForm.username = payload.username
    state.loginForm.password = payload.password
  },

  setSurveys(state, payload) {
    state.surveys = payload
  },

  setPopupShown(state, payload) {
    appStorage.set('surveyPopOverShown', payload)
    state.popupShown = payload
  },
  setPaymentSetup(state, payload) {
    state.userMeta.data.is_payment_setup = payload.is_payment_setup
  },
  setCreditWorthiness(state, payload) {
    state.creditWorthiness = payload
  },

  /**
   *
   * @param state
   * @param payload
   */
  setTimezone(state, payload) {
    state.user.timezone = payload
    appStorage.set(LS.USER, state.user)
    moment.tz.setDefault(payload)
    console.log('User timezone is set to ', payload)
  }
}
