import { BASE } from '@/modules/constants/api.js'
import { reach } from '@/modules/helpers/vuexActions.js'

import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import appStorage from '@/modules/helpers/appStorage.js'
import moment from 'moment'

export default function (context) {
  const url = `${BASE}/api/v3/core/fe-versioning`
  const method = 'GET'

  return reach(
    this,
    context,
    url,
    method,
    {
      query_params: {
        fe_key: 'ct-web'
      },
      on_success(r) {
        if (r.data?.updated_at) {
          let getVersion = appStorage.get('fe_version')
          let currentVersion = r.data?.updated_at ?? moment().toISOString()
          if (getVersion) {
            if (moment(currentVersion).isAfter(moment(getVersion))) {
              appStorage.set('fe_version', currentVersion)
              location.reload()
            }
          } else {
            appStorage.set('fe_version', currentVersion)
          }
        }
      }
    },
    axiosInstance
  )
}
