/*
|--------------------------------------------------------------------------
| Store > Auth > State
|--------------------------------------------------------------------------
|
| This file is where the Module's state properties are registered.
|
| Feel free to edit this file if you have state properties you want
| to include.
|
*/
import appStorage from '../../helpers/appStorage'

export default function () {
  return {
    userSessionLogs: {
      data: [],
      loading: false
    },
    isUserAuthenticated: false,
    user: {},
    authenticatedUsers: [],

    userMeta: {
      data: {},
      loading: false
    },

    userFeedbacks: {
      data: {},
      pagination: {},
      loading: false
    },

    isEmployedTranslator: false,

    isShowLoginModal: false,
    isShowTextTranslationLoginModal: false,

    // Login Form
    loginForm: {
      username: '',
      password: '',

      // Temporal Values
      // client_id: import.meta.env.VITE_CLIENT_ID,
      // client_secret: import.meta.env.VITE_CLIENT_SECRET,
      grant_type: 'password'
    },

    isLoadingLogin: false,

    // Register
    // this is not being used in the new registration screens
    registerForm: {
      customer_data: {
        department_id: '',
        company_id: '',
        customer_type_id: '',
        town_id: '',
        cost_place: '',
        address: '',
        address_2: '',
        post_code: '',
        city: '',
        country_iso: 'SE',
        additional_info: '',
        coordinates: '',
        allow_show_invoices: false,
        allow_send_invoices: false,
        allow_credit_card: false,
        allow_sms_confirmation: false,
        allow_appointment_webapp: false,
        allow_fallback_type: false,
        is_sensitive_info: false,
        confidential_message: '',
        is_protected_identity: false
      },
      name: '',
      notes: '',
      username: '',
      email: '',
      dob: '',
      phone: '',
      mobile: '',
      current_password: '',
      password: '',
      password_confirmation: '',
      booking_types: [],
      enabled: true,
      customer_notification_types: [],
      company_registration_id: '',
      company_name: '',
      company_address: '',
      department_name: '',
      municipality_id: '',
      token: '',
      error: false
    },
    isLoadingRegister: false,

    registerLabels: {
      company_label: '',
      department_label: ''
    },

    registerActions: {
      steps: 2,
      next: 1,
      terms_and_agreement_agreed: false
    },

    registerMunicipalities: null,

    companyOpts: {
      data: {},
      loading: false
    },

    departmentOpts: {
      data: {},
      loading: false
    },

    municipalityDepartmentOpts: {
      data: {},
      loading: false
    },

    townOpts: {
      data: {},
      loading: false
    },

    customerTypeOpts: {
      data: {},
      loading: false
    },

    // Request Reset Password Form
    requestResetPasswordForm: {
      email: '',
      via: ''
    },
    isLoadingRequestResetPassword: false,

    // Request to get passwordless login link
    requestPasswordLessLoginForm: {
      email: ''
    },
    isLoadingRequestPasswordLessLogin: false,

    // Verify Reset Password Form
    verifyResetPasswordForm: {
      email: '',
      password: '',
      password_confirmation: ''
    },
    isLoadingVerifyResetPassword: false,

    translatorOpts: {
      data: {},
      loading: false
    },

    translatorLevelOpts: {
      data: {},
      loading: false
    },

    municipalityUserOpts: {
      data: {},
      loading: false
    },

    municipalityUsers: [],

    companyUserOpts: {
      data: {},
      loading: false
    },

    municipalityDepartments: [],

    departmentUserOpts: {
      data: {},
      loading: false
    },

    pusher: null,
    isOnMobileViewport: false,
    isShowMobileNav: false,

    userNotificationPreferences: {
      loading: false,
      specific_templates: {
        booking_created: {
          send_sms: false,
          send_fax: false,
          send_email: true,
          send_push: true
        },
        booking_accepted: {
          send_sms: false,
          send_fax: false,
          send_email: true,
          send_push: true
        },
        booking_translator_first_accept: {
          send_sms: false,
          send_fax: false,
          send_email: true,
          send_push: true
        },
        positive_rating_to_customer: {
          send_sms: false,
          send_fax: false,
          send_email: true,
          send_push: true
        },
        booking_ended: {
          send_sms: false,
          send_fax: false,
          send_email: true,
          send_push: true
        },
        birthday_card: {
          send_sms: false,
          send_fax: false,
          send_email: true,
          send_push: true
        },
        positive_rating: {
          send_sms: false,
          send_fax: false,
          send_email: true,
          send_push: true
        },
        booking_duration_exceed: {
          send_sms: false,
          send_fax: false,
          send_email: true,
          send_push: true
        },
        potential_booking: {
          send_sms: false,
          send_fax: false,
          send_email: false,
          send_push: true
        },
        emergency_potential_booking: {
          send_sms: false,
          send_fax: false,
          send_email: false,
          send_push: true
        },
        specific_translator_potential_booking: {
          send_sms: false,
          send_fax: false,
          send_email: false,
          send_push: true
        },
        booking_ended_to_translator: {
          send_sms: false,
          send_fax: false,
          send_email: true,
          send_push: true
        },
        booking_accepted_by_translator: {
          send_email: false
        },
        customer_physical_video_booking_reminder: {
          send_email: false
        },
        booking_withdraw_by_translator: {
          send_email: false,
          send_push: true
        },
        booking_translator_changed: {
          send_email: false,
          send_fax: false,
          send_sms: false,
          send_push: true
        },

        booking_start_session_reminder: {
          send_email: true,
          send_push: true,
          send_fax: false,
          send_sms: false
        }
      }
    },

    black_listed_municipalities: [],

    language: 'se',

    userFilesLoading: false,
    userFiles: [],
    translatorSalaryFiles: [],
    municipalityList: [],
    disallowedTranslatorCustomerTypes: [],
    dialects: [],
    customerTypes: [],

    trainingOpts: {
      data: {},
      loading: false
    },
    videoSettings: {},
    registerPage: {
      showFeatureImage: true,
      featureImageType: 'private_company_person'
    },
    surveys: [],
    popupShown: appStorage.get('surveyPopOverShown') || false,
    creditWorthiness: null,
    defaultResponse: {
      data: {
        status: 'success',
        data: {
          translators: []
        }
      },
      status: 200,
      statusText: 'OK',
      headers: {},
      config: {},
      request: {}
    }
  }
}
